import PropTypes from 'prop-types';

import { TextSt } from 'app/shared/components/common.css';
import { MobileAppFooter } from 'app/shared/components/MobileAppFooter/MobileAppFooter';
import { TRACKING_LIST } from 'app/shared/modules/tracking/list';
import { ContainerSt, Icon } from 'app/shared/ui';
import { trackAction } from 'app/shared/utils/tracking';

import HorizontalTrustpilotWidget from '../TrustPilot/HorizontalTrustpilotWidget/HorizontalTrustpilotWidget';
import MicroComboTrustpilotWidget from '../TrustPilot/MicroComboTrustpilotWidget/MicroComboTrustpilotWidget';

import {
  FooterCopySt,
  FooterListSt,
  FooterListWideSt,
  FooterSocialIconsContainerSt,
  FooterSt,
  LinkSt,
  SocialLinkSt,
  SocialTrustpilotRowSt,
  TrustpilotContainerSt,
} from './Footer.css';
import { ConnectedSignupForm as SignupForm } from './SignupForm/ConnectedSignupForm';

export function Footer({ target, reference, isMobile }) {
  return (
    <FooterSt ref={reference}>
      <ContainerSt grid md={4} lg={3}>
        <FooterListSt>
          <LinkSt
            to="/finanzierungsratgeber"
            onClick={() =>
              trackAction(TRACKING_LIST.FINANCING.LINKS.FINANCING_GUIDE, {
                label: 'footer',
                category: `${target}`,
                description:
                  'user clicks on finanzierungsratgeber text on footer',
              })
            }
          >
            Finanzierungsratgeber
          </LinkSt>
          <LinkSt
            to="/kostenlose-beratung"
            onClick={() =>
              trackAction('alm_footer_click', {
                category: `${target}`,
                description:
                  'user clicks on ALM text (Kostenlose Beratung) on footer',
              })
            }
          >
            Kostenlose Beratung
          </LinkSt>
          <LinkSt
            to="/faq"
            onClick={() =>
              trackAction('faq_footer_click', {
                category: `${target}`,
                description: 'user clicks on faq text on footer',
              })
            }
          >
            FAQ
          </LinkSt>
          <LinkSt
            to="/verkaufen"
            data-testid="cs24-entry-point"
            onClick={() =>
              trackAction(
                TRACKING_LIST.INTEGRATIONS.CARSALE_24.FOOTER_ENTRY_POINT,
                {
                  category: `${target}`,
                  description: 'user clicks on verkaufen on footer',
                },
              )
            }
          >
            Mein Auto verkaufen
          </LinkSt>
          <LinkSt
            to="/press"
            onClick={() =>
              trackAction('press_footer_click', {
                category: `${target}`,
                description: 'user clicks on press text on footer',
              })
            }
          >
            Presse
          </LinkSt>
          <LinkSt
            to="/magazine"
            onClick={() =>
              trackAction('magazine_footer_click', {
                category: `${target}`,
                description: 'user clicks on magazine text on footer',
              })
            }
          >
            heyMagazin
          </LinkSt>
          <LinkSt
            useRouter={false}
            to="/dealer/request-invite"
            className="dealer-portal-registration"
            onClick={() =>
              trackAction('dealer_request_invite_footer_click', {
                category: `${target}`,
                description:
                  'user clicks on dealer request_invite text on footer',
              })
            }
          >
            Händleranmeldung
          </LinkSt>
        </FooterListSt>

        <FooterListSt>
          <LinkSt
            to="https://online-kuendigung.vwfs.de/"
            useRouter={false}
            target="_blank"
            onClick={() =>
              trackAction('about_footer_click', {
                category: `${target}`,
                description: 'user clicks on about text on footer',
              })
            }
          >
            Verträge hier kündigen
          </LinkSt>
          <LinkSt
            to="/about"
            onClick={() =>
              trackAction('about_footer_click', {
                category: `${target}`,
                description: 'user clicks on about text on footer',
              })
            }
          >
            Über uns
          </LinkSt>
          <LinkSt
            useRouter={false}
            to="https://heycargroup.com/careers"
            onClick={() =>
              trackAction('careers_footer_click', {
                category: `${target}`,
                description: 'user clicks on careers text on footer',
              })
            }
          >
            Karriere
          </LinkSt>
          <LinkSt
            onClick={() => {
              // eslint-disable-next-line no-underscore-dangle
              window.__ucCmp.showFirstLayer();
              trackAction('cookie_dashboard_footer_click', {
                category: `${target}`,
                description: 'user clicks on cookie dashboard text on footer',
              });
            }}
          >
            Cookie Richtlinie
          </LinkSt>
          <LinkSt
            to="/terms"
            onClick={() =>
              trackAction('terms_footer_click', {
                category: `${target}`,
                description: 'user clicks on agb text on footer',
              })
            }
          >
            AGB
          </LinkSt>
          <LinkSt
            to="/privacy"
            onClick={() =>
              trackAction('privacy_footer_click', {
                category: `${target}`,
                description: 'user clicks on privacy text on footer',
              })
            }
          >
            Datenschutz
          </LinkSt>
          <LinkSt
            to="/imprint"
            onClick={() =>
              trackAction('imprint_footer_click', {
                category: `${target}`,
                description: 'user clicks on imprint text on footer',
              })
            }
          >
            Impressum
          </LinkSt>
          <LinkSt
            to="/contact"
            onClick={() =>
              trackAction('contact_footer_click', {
                category: `${target}`,
                description: 'user clicks on contact text on footer',
              })
            }
          >
            Kontakt
          </LinkSt>
        </FooterListSt>

        <FooterListWideSt>
          hey, profitiere von exklusiven Tipps und passenden Empfehlungen zu
          deinem Gebrauchtwagenkauf!
          <SignupForm
            ariaInputLabel="E-Mail-Adresse eingeben"
            ariaButtonLabel="Abonnieren"
          />
          <TextSt size="10px" color="white">
            Du kannst dich jederzeit wieder abmelden.
          </TextSt>
        </FooterListWideSt>
      </ContainerSt>

      <ContainerSt mt="32px">
        <SocialTrustpilotRowSt>
          <FooterSocialIconsContainerSt>
            <SocialLinkSt
              to="https://www.youtube.com/channel/UCMFkEo9GkQjLH7jAYPw7doA "
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon="multimedia" />
            </SocialLinkSt>
            <SocialLinkSt
              to=" https://www.facebook.com/heycar.germany/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon="facebook" />
            </SocialLinkSt>
            <SocialLinkSt
              to="https://www.instagram.com/heycar_deutschland/?hl=de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon="instagram" />
            </SocialLinkSt>
            {/* <SocialLinkSt
              to="https://wa.me/4930255585288"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon="whatsapp" />
            </SocialLinkSt> */}
            {/* ^^Removing WA options until CRM decides to have it back */}
            <SocialLinkSt to="mailto:kundenservice@hey.car" target="_blank">
              <Icon icon="mail" />
            </SocialLinkSt>
          </FooterSocialIconsContainerSt>
          <TrustpilotContainerSt>
            {isMobile ? (
              <MicroComboTrustpilotWidget />
            ) : (
              <HorizontalTrustpilotWidget />
            )}
          </TrustpilotContainerSt>
        </SocialTrustpilotRowSt>
      </ContainerSt>
      <MobileAppFooter />
      <ContainerSt grid md={4} lg={3}>
        <FooterCopySt>
          &copy;
          {` Copyright ${new Date().getFullYear()} `}
          Mobility Trader GmbH. Alle Rechte vorbehalten.
        </FooterCopySt>
      </ContainerSt>
    </FooterSt>
  );
}

Footer.propTypes = {
  target: PropTypes.string.isRequired,
  reference: PropTypes.func,
  isMobile: PropTypes.bool,
};

Footer.defaultProps = {
  reference: null,
  isMobile: false,
};
