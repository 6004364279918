/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* istanbul ignore file */

import { lazyLoadScript } from './utils/lazyLoadScript';

export const usercentricsScript = `<script
    id="usercentrics-cmp" src="https://web.cmp.usercentrics.eu/ui/loader.js" data-settings-id="O5GPBzwySUl4S1" async>
  </script>`;

export const previewUserCentricScript = `<script
    id="usercentrics-cmp" src="https://web.cmp.usercentrics.eu/ui/loader.js" data-draft="true" data-settings-id="O5GPBzwySUl4S1" async>
  </script>`;

export const gtmScript = `<script type="text/plain" data-usercentrics="Google Tag Manager">
    (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://wvt.hey.car/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-P8MNST6');
  </script>`;

// If you want to lazy load this one, please set the isIIFE option to true
export const fontFaceObserverScript = `<script>
    (function () {
      function isSessionStorageSupported() {
        try {
          const testKey = '__some_random_key_you_are_not_going_to_use__';
          sessionStorage.setItem(testKey, testKey);
          sessionStorage.removeItem(testKey);
          return true;
        } catch (error) {
          return false;
        }
      }

      if (!isSessionStorageSupported()) {
        return;
      }

      var html = document.documentElement;
      // Using sessionStorage to avoid reloading the fontfaceobserver script in the subsequent requests.
      if (sessionStorage.fontsLoaded) {
        html.classList.add("fonts-loaded");
      } else {
        var script = document.createElement("script");
        script.src = "/assets/vendor/fontfaceobserver.js";
        script.async = true;

        script.onload = function () {
          try {
            var ObjektivFont = new FontFaceObserver("Objektiv", {});
            ObjektivFont.load(null, 10000).then(function () {
              document.documentElement.classList.add("fonts-loaded");
              sessionStorage.fontsLoaded = true;
            }, function () {
              document.documentElement.classList.add("fonts-observer-fail");
              //avoid running the observer script in the next hit if the fonts didn't load well in 10seconds for the first hit
              sessionStorage.fontsLoaded = true;
              console.log('Fonts are not available after waiting for 10 seconds');
            });
          } catch(error) {
            // If for some reason FontfaceObserver fails to observe the original font, then add a fonts observer fail class to render original font.
            document.documentElement.classList.add("fonts-observer-fail");
            sessionStorage.fontsLoaded = true;
            console.log("Error occured in font face observer script", error);
          }
        };
        document.head.appendChild(script);
      }
    })();
  </script>`;

export const checkGamScriptsAvailability = (pathname: string): boolean => {
  if (!pathname) return false;

  const isHome = pathname === '/';
  const isCLP = pathname?.startsWith('/gebrauchtwagen');
  const isPDP = pathname?.startsWith('/vehicle');
  const isAngebote = pathname?.startsWith('/angebote');

  if (!isHome && !isCLP && !isPDP && !isAngebote) return false;
  return true;
};

export const gptSource = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

/**
 * Preload Sources:
 * https://developers.google.com/publisher-tag/guides/general-best-practices#use_preload_when_appropriate
 * https://www.ctrl.blog/entry/adsense-performance-preconnect.html
 *
 * Scripts Sources:
 * https://developers.google.com/publisher-tag/guides/general-best-practices#load_from_an_official_source
 * Standard integration: https://securepubads.g.doubleclick.net/tag/js/gpt.js
 * Limited ads integration: https://pagead2.googlesyndication.com/tag/js/gpt.js
 */
export const gamScripts = ({ pathname }: { pathname: string }): string => {
  const areGamScriptsNeeded = checkGamScriptsAvailability(pathname);

  // If GAM scripts are not needed on the `pathname` route, lazy load the scripts
  if (!areGamScriptsNeeded) {
    const loadCallback = `
      function loadCallback() {
        var gptSourceScript = document.createElement('script');
        gptSourceScript.async = true;
        gptSourceScript.src = "${gptSource}";

        var googletagScript = document.createElement('script');
        googletagScript.type = 'text/javascript';
        googletagScript.text = ${JSON.stringify(`
          window.googletag = window.googletag || { cmd: [] };
          googletag.cmd.push(function() {
            googletag.pubads().collapseEmptyDivs();
            googletag.pubads().enableSingleRequest();
            googletag.enableServices();
          });
        `)}

        return function loadFn() {
          document.head.appendChild(gptSourceScript);
          document.head.appendChild(googletagScript);
        }
      };
    `;

    return lazyLoadScript(true)({
      isIIFE: true,
      msDelay: 2000,
      loadCallback,
      loadFnName: 'lazyLoadGamScripts',
      loadedStatusName: 'gamScriptsLazyLoaded',
    });
  }

  return `
    <link rel="preconnect" href="https://securepubads.g.doubleclick.net" />
    <link rel="preload" href="${gptSource}" as="script" />
    <script async src="${gptSource}"></script>
    <script type="text/javascript">
      window.googletag = window.googletag || { cmd: [] };
      googletag.cmd.push(function() {
        googletag.pubads().collapseEmptyDivs();
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
      });
    </script>
  `;
};

export const hubspotLiveChatScript = (
  host: string,
  productionMode: boolean,
): string => {
  const isStaging = host.includes('staging');
  const prodFileName = '8833071';
  const sandboxFileName = '20561742';
  const useSandboxScript = isStaging || !productionMode;
  const fileName = useSandboxScript ? sandboxFileName : prodFileName;

  return `<script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/${fileName}.js"></script>`;
};

export const trustpilotScript = (): string => {
  const loadCallback = `
  function loadCallback() {
    var s = document.createElement('script');
    s.async = true;
    s.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    return function loadFn() {
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
  };
  `;

  return lazyLoadScript(true)({
    isIIFE: true,
    msDelay: 2000,
    loadCallback,
    loadFnName: 'loadTrustpilotScript',
    loadedStatusName: 'trustpilotScriptLoaded',
  });
};
