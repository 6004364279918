/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { connect } from 'react-redux';

import { IMapStateToProps } from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.types';
import GamBannerWrapper from 'app/shared/modules/Campaign/GAM/GamBanner/GamBannerWrapper';
import { breakpointSelector } from 'app/shared/modules/globalEvents/selectors';
import { TBreakpoint } from 'app/types/style.types';

const mapStateToProps = (state: TRootState): IMapStateToProps => {
  let isBlocked = undefined;

  if (typeof window !== 'undefined') {
    const ucData = window.localStorage.getItem('ucData');

    isBlocked = ucData ? JSON.parse(ucData).gcm.adStorage === 'denied' : true;
  }

  return {
    breakpoint: breakpointSelector(state) as TBreakpoint,
    isBlocked,
  };
};

export const ConnectedGamBanner = connect(mapStateToProps)(GamBannerWrapper);
